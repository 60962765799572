import React, { useEffect, useState } from "react";
import axios from "axios";
import md5 from "md5";  
import { doc, getDoc } from 'firebase/firestore';
import { useUser } from "../context/userContext";
import { db } from '../firebase/firestore';  

const Wallet = () => {
  const { id } = useUser();
  const [telegramUser, setTelegramUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);  // State to control popup visibility

  // Function to fetch user data from Firestore
  const fetchTelegramUser = async () => {
    try {
      const docRef = doc(db, "telegramUsers", id.toString());
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setTelegramUser(userData);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchTelegramUser();  
    }
  }, [id]);

  const sendLoginData = async () => {
    if (!telegramUser) return;

    const user_id = telegramUser.userId;
    const upline = telegramUser.refereeId;
    const username = telegramUser.username;
    const time = Math.floor(Date.now() / 1000);
    const secret_key = "VPZ5XfLruQ97hAPOmT4gpCDU6cnjN";
    const sign = md5(`${user_id}&${time}&${secret_key}`);

    try {
      const response = await axios.post("https://ibmbot.com/api/login/access", {
        user_id,
        username,
        time,
        upline,
        sign,
      });

      if (response.status === 200 && response.data.code === 1) {
        window.location.href = response.data.data;
      } else {
        console.error("Login failed:", response.data.msg);
        setShowPopup(true);  // Show popup if response status is not 200
      }
    } catch (error) {
      console.error("Error during login:", error);
      setShowPopup(true);  // Show popup on request failure
    }
  };

  useEffect(() => {
    if (telegramUser) {
      sendLoginData();  
    }
  }, [telegramUser]);

  return (
    <div>
    

      {showPopup && (
        <div className="popup">
          <p>Error loading wallet, please reload the page.</p>
          <button onClick={() => window.location.reload()}>Reload</button>
        </div>
      )}
    </div>
  );
};

export default Wallet;
